import i18n from './i18n'
import { getBaseUrl } from './utils'
import * as _ from 'lodash'
import { TranslationOptions } from 'i18next'

const FALLBACK_TRANSLATIONS_FILE = 'static.parastorage.com/services/wix-form-builder/1.817.0'
const BASE_URL = `https://${getBaseUrl() || FALLBACK_TRANSLATIONS_FILE}/`

export interface InitOptions {
  fallbackToDefaultValueOnException?: boolean
  origin?: string
}

class TranslationsInstance {
  private _t: Function
  private _fallbackToDefaultValueOnException: boolean

  constructor() {
    this.init = this.init.bind(this)
  }

  async init(locale, { origin = 'builder', fallbackToDefaultValueOnException = false } = {}) {
    this._fallbackToDefaultValueOnException = fallbackToDefaultValueOnException

    try {
      const { t } = await i18n({ locale, baseUrl: BASE_URL, origin })
      this._t = t
    } catch {
      return Promise.reject(new Error(`failed to load ${origin}/${locale} translations from ${BASE_URL}`))
    }
  }

  setTranslationInstance(t) {
    this._t = t
  }

  t = (key, options?: TranslationOptions) => {
    if (!this._t) {
      if (!this._fallbackToDefaultValueOnException) {
        throw new Error('Translation service was not initialized yet!')
      } else {
        return _.get(options, 'defaultValue', '')
      }
    }

    return this._t(key, options)
  }
}

export default new TranslationsInstance()
