import * as _ from 'lodash'
import { ROLE_DOWNLOAD_MESSAGE, ROLE_FORM, ROLE_MESSAGE } from '../../../constants/roles'
import { getFieldPreset, getFormPreset } from '../preset/preset-service'
import { getFieldStyle, commonStyles } from './form-style-service'
import { getHiddenMessagePreset, getSubmitButtonPreset } from '../components/form-structures'
import translations from '../../../utils/translations'
import { escapeRegExp, innerText } from '../../../utils/utils'
import { SecondsToResetDefaults, SuccessActionTypes } from '../../../constants/success-settings'
import { FormPreset } from '../../../constants/form-types'
import { ButtonType, FieldPreset, MessageType } from '../../../constants/field-types'
import { formComponent, FormSnapshot, ComponentSnapshot } from '../api-types'

const convertToInnerStructure = ({ role, connectionConfig, ...rest }: any) => ({
  role,
  connectionConfig,
  data: rest,
})

const convertToSnapshotInnerStructure = ({ role, config, ...rest }: ComponentSnapshot) => ({
  role,
  connectionConfig: config,
  data: _.omit(rest, 'componentRef'),
})

const convertFormComponents = (formComponent, formSnapshot?: FormSnapshot) => {
  const hasSnapshot = formSnapshot && formSnapshot.formComponent
  const fields = hasSnapshot
    ? formSnapshot.components.map(convertToSnapshotInnerStructure)
    : formComponent.components.map(convertToInnerStructure)

  const initialBox = convertToInnerStructure(_.omit(formComponent, 'components'))

  const box = hasSnapshot
    ? _.merge(initialBox, {
        connectionConfig: formSnapshot.formComponent.config,
        data: { layout: formSnapshot.formComponent.layout },
      })
    : initialBox

  return {
    box,
    fields,
  }
}

const getConnectionConfig = (presetKey, comp) => {
  if (comp.role !== ROLE_FORM) {
    return {}
  }
  const config = {
    preset: presetKey,
    labels: ['contacts-contacted_me'],
    errorMessage:
      presetKey === FormPreset.REGISTRATION_FORM
        ? translations.t('settings.errorMessage.registrationForm')
        : translations.t('settings.errorMessage.default'),
    secondsToResetForm: SecondsToResetDefaults.MIN,
    successActionType: SuccessActionTypes.SHOW_MESSAGE,
    styles: {
      input: {
        bg: 'color_11',
        bge: 'color_11',
        bgf: 'color_11',
        bgh: 'color_11',
        brd: 'color_15',
        brde: '#f60419',
        brdf: 'color_18',
        brdh: 'color_15',
        fnt: 'font_8',
        txt: 'color_15',
        txt2: 'color_14',
      },
      box: { bg: 'color_11' },
    },
  }
  return config
}

export const getExtraMessageText = ({ data, presetKey = '', newMessage }) => {
  const parsedMessage = innerText(data.text);
  return {
    text: data.text.replace(
      new RegExp(`>${escapeRegExp(innerText(data.text))}`),
      `>${newMessage ||
      (presetKey === FormPreset.REGISTRATION_FORM
        ? translations.t('settings.errorMessage.registrationForm')
        : parsedMessage)}`
    ),
  }
}

const getChildComponents = (presetKey, comp) =>
  comp.components &&
  comp.components.map(childComp => deConstructComponent({ presetKey, rawComp: childComp }))

const deConstructComponent = ({ presetKey, rawComp, newMessage = null }: formComponent) => {
  const comp = rawComp
  comp.connectionConfig = _.merge(
    {},
    comp.config,
    getConnectionConfig(presetKey, comp),
    comp.connectionConfig
  )
  if (comp.role === ROLE_MESSAGE || comp.role === ROLE_DOWNLOAD_MESSAGE) {
    comp.data = _.merge(
      {},
      comp.data,
      getExtraMessageText({ data: comp.data, presetKey, newMessage })
    )
  }
  comp.components = getChildComponents(presetKey, comp)
  return comp
}

export const fetchPreset = async (
  presetKey: FormPreset,
  locale: string = 'en',
  onFailedPresetCallback: Function = _.noop
) => {
  let rawPreset
  try {
    rawPreset = await getFormPreset(presetKey, locale)
  } catch (e) {
    await onFailedPresetCallback(`${presetKey}: ${(<Error>e).message}`)
    return
  }
  return rawPreset
}

// return { box, fields }
export const createForm = async (
  presetKey,
  { coords = { x: 175 }, locale = 'en' } = {},
  onFailedPresetCallback = _.noop,
  formSnapshot?: FormSnapshot
) => {
  const rawPreset = await fetchPreset(presetKey, locale, onFailedPresetCallback)
  if (!rawPreset) {
    return
  }

  const formComponent = _.merge(
    {
      style: {
        style: {
          properties: { loader: 0 },
        },
        skin: 'wysiwyg.viewer.skins.FormContainerSkin',
      },
    },
    deConstructComponent({ presetKey, rawComp: rawPreset }),
    {
      layout: coords || { x: 175 },
    }
  )

  delete formComponent.layout.y
  const { box, fields } = convertFormComponents(formComponent, formSnapshot)
  const plugins = _.get(rawPreset, 'config.plugins')

  return { box, fields, plugins }
}

// return { role, connectionConfig, data }
export const createField = (
  presetKey,
  {
    fieldType,
    extraData,
    commonStyles,
    formWidth,
  }: { fieldType: FieldPreset; extraData: any; commonStyles: commonStyles; formWidth: number },
  layout,
  plugins
) => {
  // TODO remove presetKey
  const rawPreset = getFieldPreset({ fieldType, extraData, plugins })
  const width = Math.min(formWidth - layout.x, layout.width || rawPreset.layout.width)
  const fieldComponent = _.merge({}, deConstructComponent({ presetKey, rawComp: rawPreset }), {
    layout: { ...layout, width },
  })
  const fieldStyle = getFieldStyle(commonStyles, fieldType)
  _.assign(fieldComponent.style.style.properties, fieldStyle)
  return convertToInnerStructure(fieldComponent)
}

// return { role, connectionConfig, data }
export const createSubmitButton = async (
  { layout, buttonType },
  { presetKey, locale, onFailedPresetCallback }
) => {
  const rawPreset = await getSubmitButtonPreset(buttonType, {
    presetKey,
    locale,
    onFailedPresetCallback,
  })
  const data = {
    label: translations.t(
      `preset.${buttonType === ButtonType.SUBMIT ? 'submit' : 'signup'}ButtonLabel`
    ),
  }
  const buttonComponent = _.merge({}, deConstructComponent({ presetKey: '', rawComp: rawPreset }), {
    layout,
    data,
  })
  return convertToInnerStructure(buttonComponent)
}

// return { role, connectionConfig, data }
export const createHiddenMessage = async (
  { layout, hiddenMessageType, newMessage, formWidth },
  { presetKey, locale, onFailedPresetCallback }
) => {
  const rawPreset = await getHiddenMessagePreset(hiddenMessageType, {
    presetKey,
    locale,
    onFailedPresetCallback,
  })
  const isCenterAligned =
    rawPreset.data.text.match(/text-align:[\s]*center/) && rawPreset.layout.x === 0
  const messageWidth = isCenterAligned ? formWidth : rawPreset.layout.width

  if (hiddenMessageType === MessageType.DOWNLOAD) {
    rawPreset.role = ROLE_DOWNLOAD_MESSAGE
  }

  const hiddenMessageComponent = _.merge(
    {},
    deConstructComponent({
      presetKey: hiddenMessageType === MessageType.REGISTRATION ? FormPreset.REGISTRATION_FORM : '',
      rawComp: rawPreset,
      newMessage,
    }),
    { layout: { ...layout, width: messageWidth } }
  )
  return convertToInnerStructure(hiddenMessageComponent)
}
