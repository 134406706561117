import * as _ from 'lodash'
import { bindObjectFunctions } from '../../utils/utils'
import translations from '../../utils/translations'
import Experiments from '@wix/wix-experiments'
import { createCollectionsApi as createWixCodeCollectionsApi } from '@wix/wix-code-collections-api'
import { createPermissionsApi as createWixCodeCollectionPermissionsApi } from '@wix/wix-code-collections-permissions-api'
import * as createEditorTransportLayer from '@wix/wix-code-collections-transport-editor'
import { initBiLoggerForEditorApp } from '../../utils/bi'
import { ORIGINS } from '../../constants/bi'
import * as Raven from 'raven-js'
import RemoteApi from '../../panels/commons/remote-api'
import { FormPreset } from '../../constants/form-types'
import CoreApi from '../core/core-api'
import { createPanelDefs } from '../core/services/panel-definitions'
import { setBiLogger, setPanelDefinitions } from '../editor-app-impl'
import { addForm, openAddFormPanel } from '../core-actions'
import { fetcher } from '../../utils/utils'
import { fetchAllThemes } from '../core/preset/themes-service'

const EDITOR_INITIATOR = 'EDITOR'

const fetchCoreApi = fetcher()
export const getApi = () => fetchCoreApi.getData // race-condition editorReady & onEvent

export const startApp = async (origin) => {
  if (!origin) {
    return
  }

  const api: CoreApi = await getApi()
  api.updateOrigin(origin)

  const addFormWithPresetFromAddPanel = _.get(origin, 'info.preset')

  if (addFormWithPresetFromAddPanel) {
    const payload = {
      containerRef: origin.info.containerRef,
      coords: origin.info.coords,
      theme: origin.info.theme,
      source_name: ORIGINS.ADD_PANEL,
      select_form: origin.info.select_form
    }

    const createCollection = api.shouldCreateAutoCollection()

    if (createCollection) {
      return api.addForm.AddFormWithCollection(addFormWithPresetFromAddPanel, payload)
    } else {
      await api.addForm.addForm(addFormWithPresetFromAddPanel, payload)
      return api.saveSite()
    }
  } else if (_.get(origin, 'info.type') === FormPreset.REGISTRATION_FORM) {
    await addForm(origin.info.preset || FormPreset.REGISTRATION_FORM, {
      containerRef: origin.info.containerRef,
      source_name: ORIGINS.SIGNUP_SETTINGS,
    })

    return api.saveSite()
  } else if (origin.initiator === EDITOR_INITIATOR) {
    return openAddFormPanel()
  }
}

export const initApp = async ({ appDefinitionId, editorSDK, msid, origin }) => {
  const { coreApi, boundEditorSDK, biLogger } = await initCoreApi({
    appDefinitionId,
    editorSDK,
    msid,
    origin,
  })
  const [locale] = await Promise.all([
    boundEditorSDK.environment.getLocale(),
    boundEditorSDK.editor.setAppAPI(coreApi.generateRuntimeApi()),
  ])
  await translations.init(locale)

  setBiLogger(biLogger)

  coreApi.appState.setState()
  fetchCoreApi.resolveData(coreApi)

  const panelDefinitions = createPanelDefs(
    msid
  )
  setPanelDefinitions(panelDefinitions)

  fetchAllThemes()
}

const initCoreApi = async ({ appDefinitionId, editorSDK, msid, origin }) => {
  const experiments = new Experiments({ baseUrl: 'https://www.wix.com' })
  await experiments.load('wix-form-builder')
  const boundEditorSDK = bindObjectFunctions(editorSDK, appDefinitionId)
  const transportLayer = createEditorTransportLayer(editorSDK, appDefinitionId)
  const collectionsApi = createWixCodeCollectionsApi({ transportLayer, })
  const collectionsPermissionsApi = createWixCodeCollectionPermissionsApi({
    editorSDK,
    token: appDefinitionId,
    saveToSchema: experiments.enabled('specs.wixCode.SavePermissionsToSchema')
  })

  const remoteApi = new RemoteApi({ boundEditorSDK })
  const biLogger = await initBiLoggerForEditorApp(msid, origin)

  const coreApi = new CoreApi(boundEditorSDK, editorSDK, {
    apis: {
      collectionsApi,
      collectionsPermissionsApi,
      remoteApi,
    },
    experiments,
    ravenInstance: Raven,
    biLogger,
    origin,
    appDefinitionId
  })

  return { coreApi, boundEditorSDK, biLogger }
}
