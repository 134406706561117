import { FormsFieldPreset, AdiFieldPreset, FieldPreset } from '../../../../../constants/field-types'

export const EDIT_FORM_PANEL_SPACER = 9
export const POPOVER_WIDTH = 135
export const PREMIUM_ICON_COLOR = '#bd10e0'

export const enum SETTINGS_POPOVER_ID {
  EDIT = 'EDIT',
  MAKE_REQUIRED = 'MAKE_REQUIRED',
  DELETE = 'DELETE',
}

export const ADI_FIELDS_RESTRICTIONS = {
  [AdiFieldPreset.ADI_UPLOAD_BUTTON]: FormsFieldPreset.GENERAL_UPLOAD_BUTTON,
}

export const ADI_FIELDS_WITHOUT_LABEL_TOGGLE: FieldPreset[] = [
  FormsFieldPreset.GENERAL_SINGLE_CHECKBOX,
  FormsFieldPreset.GENERAL_SUBSCRIBE,
  FormsFieldPreset.GENERAL_CHECKBOX,
  FormsFieldPreset.GENERAL_RADIO_BUTTON,
]
export type PopoverData = {
  type: ADD_FIELD_PANEL_TYPES
  name?: string
  labelKey?: string
  group? : string
}

export const enum ADD_FIELD_PANEL_TYPES {
  FIELD = 'FIELD',
  PREMIUM_FIELD = 'PREMIUM_FIELD',
  TITLE = 'TITLE',
  DIVIDER = 'DIVIDER'
}

const enum ADD_FIELD_PANEL_GROUPS {
  POPULAR = 'custom_fields',
  GENERAL = 'general_fields'
}

export const ADD_FIELD_PANEL_DATA: Array<PopoverData> = [
  {
    type: ADD_FIELD_PANEL_TYPES.TITLE,
    name: ADD_FIELD_PANEL_GROUPS.POPULAR,
    labelKey: 'adi.editFormPanel.addField.customTitle',
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: AdiFieldPreset.ADI_FIRST_NAME,
    labelKey: 'adi.editFormPanel.firstName',
    group: ADD_FIELD_PANEL_GROUPS.POPULAR,
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: AdiFieldPreset.ADI_LAST_NAME,
    labelKey: 'adi.editFormPanel.lastName',
    group: ADD_FIELD_PANEL_GROUPS.POPULAR,
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: AdiFieldPreset.ADI_EMAIL,
    labelKey: 'adi.editFormPanel.email',
    group: ADD_FIELD_PANEL_GROUPS.POPULAR,
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: AdiFieldPreset.ADI_PHONE,
    labelKey: 'adi.editFormPanel.phoneNumber',
    group: ADD_FIELD_PANEL_GROUPS.POPULAR,
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: AdiFieldPreset.ADI_ADDRESS,
    labelKey: 'adi.editFormPanel.address',
    group: ADD_FIELD_PANEL_GROUPS.POPULAR,
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: AdiFieldPreset.ADI_DATE,
    labelKey: 'adi.editFormPanel.date',
    group: ADD_FIELD_PANEL_GROUPS.POPULAR,
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: AdiFieldPreset.ADI_URL,
    labelKey: 'adi.editFormPanel.url',
    group: ADD_FIELD_PANEL_GROUPS.POPULAR,
  },
  { type: ADD_FIELD_PANEL_TYPES.DIVIDER },
  {
    type: ADD_FIELD_PANEL_TYPES.TITLE,
    name: ADD_FIELD_PANEL_GROUPS.GENERAL,
    labelKey: 'adi.editFormPanel.addField.generalTitle',
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: AdiFieldPreset.ADI_GENERAL_TEXT,
    labelKey: 'adi.editFormPanel.shortText',
    group: ADD_FIELD_PANEL_GROUPS.GENERAL,
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: AdiFieldPreset.ADI_GENERAL_TEXT_BOX,
    labelKey: 'adi.editFormPanel.paragraph',
    group: ADD_FIELD_PANEL_GROUPS.GENERAL,
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: AdiFieldPreset.ADI_NUMBER,
    labelKey: 'adi.editFormPanel.number',
    group: ADD_FIELD_PANEL_GROUPS.GENERAL,
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: FormsFieldPreset.GENERAL_RADIO_BUTTON,
    labelKey: 'adi.editFormPanel.singleSelection',
    group: ADD_FIELD_PANEL_GROUPS.GENERAL,
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: FormsFieldPreset.GENERAL_SINGLE_CHECKBOX,
    labelKey: 'adi.editFormPanel.singleCheckbox',
    group: ADD_FIELD_PANEL_GROUPS.GENERAL,
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: FormsFieldPreset.GENERAL_CHECKBOX,
    labelKey: 'adi.editFormPanel.multipleSelection',
    group: ADD_FIELD_PANEL_GROUPS.GENERAL,
  },
  {
    type: ADD_FIELD_PANEL_TYPES.FIELD,
    name: AdiFieldPreset.ADI_GENERAL_DROPDOWN,
    labelKey: 'adi.editFormPanel.dropdown',
    group: ADD_FIELD_PANEL_GROUPS.GENERAL,
  },
  {
    type: ADD_FIELD_PANEL_TYPES.PREMIUM_FIELD,
    name: AdiFieldPreset.ADI_UPLOAD_BUTTON,
    labelKey: 'adi.editFormPanel.uploadFile',
    group: ADD_FIELD_PANEL_GROUPS.GENERAL,
  },
]
